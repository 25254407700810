import { GET_CAMPAIGNS, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGN_DETAILS_SUCCESS, GET_CAMPAIGNS_FAILURE, GET_CAMPAIGN_DETAILS_FAILURE, CREATE_CAMPAIGN, CREATE_CAMPAIGN_SUCCESS, CREATE_CAMPAIGN_FAILURE, GET_CAMPAIGN_DETAILS } from './types';
import { getCampaignsApi, createCampaignApi, getCampaignDetailsApi } from '../services/apiService';

export const getCampaigns = (clientId) => async (dispatch) => {
  dispatch({ type: GET_CAMPAIGNS });
  try {
    const response = await getCampaignsApi(clientId);
    dispatch({
      type: GET_CAMPAIGNS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CAMPAIGNS_FAILURE,
      payload: error.message,
    });
  }
};

export const createCampaign = (campaignData) => async (dispatch) => {
  dispatch({ type: CREATE_CAMPAIGN });
  try {
    const response = await createCampaignApi(campaignData);
    dispatch({
      type: CREATE_CAMPAIGN_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_CAMPAIGN_FAILURE,
      payload: error.message,
    });
  }
};

export const getCampaignDetails = (campaignId) => async (dispatch) => {
  dispatch({ type: GET_CAMPAIGN_DETAILS });
  try {
    const response = await getCampaignDetailsApi(campaignId);
    if (response.data && response.data.length > 0) {
      console.log(response.data[0]); // This will log the first item in the response array.
      dispatch({
        type: GET_CAMPAIGN_DETAILS_SUCCESS,
        payload: response.data[0], // Dispatching the first object in the array.
      });
    } else {
      dispatch({
        type: GET_CAMPAIGN_DETAILS_FAILURE,
        payload: 'No data found',
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CAMPAIGN_DETAILS_FAILURE,
      payload: error.message,
    });
  }
};
