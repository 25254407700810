// src/actions/authActions.js
import { login as apiLogin, register as apiRegister } from '../services/apiService';
import { LOGIN_SUCCESS, LOGIN_FAIL, REGISTER_SUCCESS, REGISTER_FAIL, LOGOUT } from './types';

export const login = (credentials) => async (dispatch) => {
  try {
    const response = await apiLogin(credentials);
    if (response.data && response.data.length !== 0) {
    const userData = response.data;
    localStorage.setItem('auth', JSON.stringify(userData)); // Save auth data to local storage
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: 'Invalid login credentials',
      });
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response.data.message || 'Something went wrong',
    });
  }
};

export const register = (userData) => async (dispatch) => {
  try {
    const response = await apiRegister(userData);
    dispatch({ type: REGISTER_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: REGISTER_FAIL, payload: error.message });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('auth'); // Remove auth data from local storage
  dispatch({ type: LOGOUT });
};
