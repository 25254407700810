import { combineReducers } from 'redux';
import contactReducer from './contactReducer';
import campaignReducer from './campaignReducer';
import reportReducer from './reportReducer';
import authReducer
 from './authReducer';
import billingReducer from './billingReducer';
export default combineReducers({
  contact: contactReducer,
  // other reducers
  auth: authReducer,

  campaign: campaignReducer,
  report: reportReducer,
  billing: billingReducer

});
