import { GET_CAMPAIGNS, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_FAILURE, GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_FAILURE } from '../actions/types';

const initialState = {
  campaigns: [],
  report: {},  // Changed this to an empty object based on your API response format
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CAMPAIGNS:
    case GET_REPORT:
      return {
        ...state,
        loading: true,
      };
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        loading: false,
      };
    case GET_REPORT_SUCCESS:
      console.log('Reducer Report Data:', action.payload); // Logs payload in reducer
      return {
        ...state,
        report: action.payload,  // If the payload is an object, it's better to initialize it as an object
        loading: false,
      };
    case GET_CAMPAIGNS_FAILURE:
    case GET_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
