import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Switch, FormControlLabel, Button, Paper, Typography, Tabs, Tab, Slider, Tooltip, Modal, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getAssistants } from '../services/apiService'; // Ensure this API service function is defined properly

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 800,
    overflowY: 'auto',
    maxHeight: '90vh',
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  switchControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  sliderControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sliderValue: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  assistantList: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  assistantListItem: {
    padding: theme.spacing(1),
    borderBottom: '1px solid #ddd',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 600,
  },
}));

const AssistantConfig = () => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const [assistantName, setAssistantName] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [agentConfig, setAgentConfig] = useState({
    agentName: '',
    agentType: 'other',
    agentWelcomeMessage: '',
    toolchainExecution: 'parallel',
    transcriberConfig: {
      language: 'hi',
      model: 'deepgram',
      stream: true,
      samplingRate: 16000,
      endpointing: 100,
      modelType: 'whisper-tiny',
    },
    llmAgentConfig: {
      agentFlowType: 'streaming',
      family: 'openai',
      model: 'gpt-4o',
      useFallback: true,
      maxTokens: 100,
      temperature: 0.1,
      topK: 0,
      topP: 0.9,
      minP: 0.1,
      frequencyPenalty: 0,
      presencePenalty: 0,
    },
    synthesizerConfig: {
      audioFormat: 'mp3',
      provider: 'ivr',
      stream: true,
      provider_config: {
        voice: 'Monika',
        model: 'eleven_multilingual_v2',
        voiceId: '1qEiC6qsybMkmnNdVMbK',
      },
      bufferSize: 100,
    },
    taskConfig: {
      optimizeLatency: true,
      hangupAfterSilence: 10,
      incrementalDelay: 100,
      numberOfWordsForInterruption: 2,
      interruptionBackoffPeriod: 100,
      hangupAfterLLMCall: true,
      backchanneling: false,
      backchannelingMessageGap: 5,
      backchannelingStartDelay: 5,
      ambientNoise: false,
    },
    systemPrompt: '',
  });

  useEffect(() => {
    const fetchAssistants = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem('auth'));
        const response = await getAssistants({ clientId: userData._id });
        console.log(userData._id,"reee") // replace 'your-client-id' with actual client ID
        setAssistants(Array.isArray(response.data) ? response.data : []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching assistants:', error);
        setLoading(false);
      }
    };

    fetchAssistants();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAgentConfig({ ...agentConfig, [name]: value });
  };

  const handleNestedInputChange = (section, e) => {
    const { name, value } = e.target;
    setAgentConfig({ ...agentConfig, [section]: { ...agentConfig[section], [name]: value } });
  };

  const handleSwitchChange = (section, e) => {
    const { name, checked } = e.target;
    setAgentConfig({ ...agentConfig, [section]: { ...agentConfig[section], [name]: checked } });
  };

  const handleSliderChange = (section, name) => (event, newValue) => {
    setAgentConfig({ ...agentConfig, [section]: { ...agentConfig[section], [name]: newValue } });
  };

  const handleSubmit = () => {
    const payload = {
      agent_config: {
        agent_name: assistantName,
        agent_type: agentConfig.agentType,
        agent_welcome_message: agentConfig.agentWelcomeMessage,
        tasks: [
          {
            task_type: 'conversation',
            toolchain: {
              execution: agentConfig.toolchainExecution,
              pipelines: [['transcriber', 'llm', 'synthesizer']],
            },
            tools_config: {
              input: {
                format: 'pcm',
                provider: 'exotel',
              },
              llm_agent: agentConfig.llmAgentConfig,
              output: {
                format: 'pcm',
                provider: 'exotel',
              },
              synthesizer: agentConfig.synthesizerConfig,
              transcriber: agentConfig.transcriberConfig,
            },
            task_config: agentConfig.taskConfig,
          },
        ],
      },
      agent_prompts: {
        task_1: {
          system_prompt: agentConfig.systemPrompt,
        },
      },
      agent_id: '668f832f13a149ed750e7d82',
    };

    console.log(payload);
    // Here you would send the payload to the API
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleAssistantNameChange = (e) => {
    setAssistantName(e.target.value);
  };

  const startCreatingAssistant = () => {
    setShowForm(true);
    setModalOpen(false);
  };

  return (
    <div className={classes.root}>
      {!showForm && (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setModalOpen(true)}
            style={{ marginBottom: 20 }}
          >
            Create Assistant
          </Button>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className={classes.assistantList}>
              <Typography variant="h6">Available Assistants</Typography>
              {assistants.map((assistant) => (
                <div key={assistant.id} className={classes.assistantListItem}>
                  {assistant.name}
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {showForm && (
        <>
          <Typography variant="h6" gutterBottom>
            Creating Assistant: {assistantName}
          </Typography>
          <Paper className={classes.paper} elevation={6}>
            <Typography variant="h5" gutterBottom>Assistant Configuration</Typography>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Model" />
              <Tab label="Transcriber" />
              <Tab label="Voice" />
              <Tab label="Advanced" />
            </Tabs>
            {tabIndex === 0 && (
              <div>
                <Typography variant="h6" className={classes.sectionTitle}>Model Configuration</Typography>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="First Message"
                    variant="outlined"
                    name="agentWelcomeMessage"
                    value={agentConfig.agentWelcomeMessage}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="System Prompt"
                    variant="outlined"
                    name="systemPrompt"
                    value={agentConfig.systemPrompt}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel>Model</InputLabel>
                  <Select
                    name="model"
                    value={agentConfig.llmAgentConfig.model}
                    onChange={(e) => handleNestedInputChange('llmAgentConfig', e)}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="gpt-3.5">gpt-3.5</MenuItem>
                    <MenuItem value="gpt-4">gpt-4</MenuItem>
                    <MenuItem value="gpt-4o">gpt-4o</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel>Use Fallback</InputLabel>
                  <Select
                    name="useFallback"
                    value={agentConfig.llmAgentConfig.useFallback}
                    onChange={(e) => handleNestedInputChange('llmAgentConfig', e)}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Max Tokens"
                    variant="outlined"
                    name="maxTokens"
                    type="number"
                    value={agentConfig.llmAgentConfig.maxTokens}
                    onChange={(e) => handleNestedInputChange('llmAgentConfig', e)}
                    fullWidth
                  />
                </FormControl>
                <Typography gutterBottom>Temperature</Typography>
                <Slider
                  className={classes.sliderControl}
                  value={agentConfig.llmAgentConfig.temperature}
                  onChange={handleSliderChange('llmAgentConfig', 'temperature')}
                  aria-labelledby="temperature-slider"
                  step={0.1}
                  min={0}
                  max={1}
                />
                <Typography className={classes.sliderValue}>{agentConfig.llmAgentConfig.temperature}</Typography>
              </div>
            )}
            {tabIndex === 1 && (
              <div>
                <Typography variant="h6" className={classes.sectionTitle}>Transcriber Configuration</Typography>
                <FormControl className={classes.formControl}>
                  <InputLabel>Language</InputLabel>
                  <Select
                    name="language"
                    value={agentConfig.transcriberConfig.language}
                    onChange={(e) => handleNestedInputChange('transcriberConfig', e)}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="hi">Hindi</MenuItem>
                    <MenuItem value="en">English</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel>Stream</InputLabel>
                  <Select
                    name="stream"
                    value={agentConfig.transcriberConfig.stream}
                    onChange={(e) => handleNestedInputChange('transcriberConfig', e)}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel>Model Type</InputLabel>
                  <Select
                    name="modelType"
                    value={agentConfig.transcriberConfig.modelType}
                    onChange={(e) => handleNestedInputChange('transcriberConfig', e)}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="whisper-tiny">Whisper-Tiny</MenuItem>
                    <MenuItem value="nova">Nova</MenuItem>
                    <MenuItem value="nova-2">Nova-2</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Sampling Rate"
                    variant="outlined"
                    name="samplingRate"
                    type="number"
                    value={agentConfig.transcriberConfig.samplingRate}
                    onChange={(e) => handleNestedInputChange('transcriberConfig', e)}
                    fullWidth
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Endpointing"
                    variant="outlined"
                    name="endpointing"
                    type="number"
                    value={agentConfig.transcriberConfig.endpointing}
                    onChange={(e) => handleNestedInputChange('transcriberConfig', e)}
                    fullWidth
                  />
                </FormControl>
              </div>
            )}
            {tabIndex === 2 && (
              <div>
                <Typography variant="h6" className={classes.sectionTitle}>Voice Configuration</Typography>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Voice"
                    variant="outlined"
                    name="voice"
                    value={agentConfig.synthesizerConfig.provider_config.voice}
                    onChange={(e) => handleNestedInputChange('synthesizerConfig', e)}
                    fullWidth
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Model"
                    variant="outlined"
                    name="model"
                    value={agentConfig.synthesizerConfig.provider_config.model}
                    onChange={(e) => handleNestedInputChange('synthesizerConfig', e)}
                    fullWidth
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Voice ID"
                    variant="outlined"
                    name="voiceId"
                    value={agentConfig.synthesizerConfig.provider_config.voiceId}
                    onChange={(e) => handleNestedInputChange('synthesizerConfig', e)}
                    fullWidth
                  />
                </FormControl>
              </div>
            )}
            {tabIndex === 3 && (
              <div>
                <Typography variant="h6" className={classes.sectionTitle}>Advanced Configuration</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={agentConfig.taskConfig.optimizeLatency}
                      onChange={(e) => handleSwitchChange('taskConfig', e)}
                      name="optimizeLatency"
                      color="primary"
                    />
                  }
                  label="Optimize Latency"
                  className={classes.switchControl}
                />
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Hangup After Silence (s)"
                    variant="outlined"
                    name="hangupAfterSilence"
                    type="number"
                    value={agentConfig.taskConfig.hangupAfterSilence}
                    onChange={(e) => handleNestedInputChange('taskConfig', e)}
                    fullWidth
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Incremental Delay"
                    variant="outlined"
                    name="incrementalDelay"
                    type="number"
                    value={agentConfig.taskConfig.incrementalDelay}
                    onChange={(e) => handleNestedInputChange('taskConfig', e)}
                    fullWidth
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Number of Words for Interruption"
                    variant="outlined"
                    name="numberOfWordsForInterruption"
                    type="number"
                    value={agentConfig.taskConfig.numberOfWordsForInterruption}
                    onChange={(e) => handleNestedInputChange('taskConfig', e)}
                    fullWidth
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Interruption Backoff Period"
                    variant="outlined"
                    name="interruptionBackoffPeriod"
                    type="number"
                    value={agentConfig.taskConfig.interruptionBackoffPeriod}
                    onChange={(e) => handleNestedInputChange('taskConfig', e)}
                    fullWidth
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={agentConfig.taskConfig.hangupAfterLLMCall}
                      onChange={(e) => handleSwitchChange('taskConfig', e)}
                      name="hangupAfterLLMCall"
                      color="primary"
                    />
                  }
                  label="Hangup After LLM Call"
                  className={classes.switchControl}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={agentConfig.taskConfig.backchanneling}
                      onChange={(e) => handleSwitchChange('taskConfig', e)}
                      name="backchanneling"
                      color="primary"
                    />
                  }
                  label="Backchanneling"
                  className={classes.switchControl}
                />
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Backchanneling Message Gap"
                    variant="outlined"
                    name="backchannelingMessageGap"
                    type="number"
                    value={agentConfig.taskConfig.backchannelingMessageGap}
                    onChange={(e) => handleNestedInputChange('taskConfig', e)}
                    fullWidth
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Backchanneling Start Delay"
                    variant="outlined"
                    name="backchannelingStartDelay"
                    type="number"
                    value={agentConfig.taskConfig.backchannelingStartDelay}
                    onChange={(e) => handleNestedInputChange('taskConfig', e)}
                    fullWidth
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={agentConfig.taskConfig.ambientNoise}
                      onChange={(e) => handleSwitchChange('taskConfig', e)}
                      name="ambientNoise"
                      color="primary"
                    />
                  }
                  label="Ambient Noise"
                  className={classes.switchControl}
                />
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.submitButton}
              fullWidth
            >
              Save Configuration
            </Button>
          </Paper>
        </>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} className={classes.modal}>
        <div className={classes.modalPaper}>
          <Typography variant="h6" gutterBottom>
            Enter Assistant Name
          </Typography>
          <TextField
            label="Assistant Name"
            variant="outlined"
            name="assistantName"
            value={assistantName}
            onChange={handleAssistantNameChange}
            fullWidth
            style={{ marginBottom: 20 }}
          />
          <Button variant="contained" color="primary" onClick={startCreatingAssistant} fullWidth>
            Continue
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AssistantConfig;
