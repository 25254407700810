import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  Alert,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Papa from 'papaparse';
import { format } from 'date-fns';
import { getContactLists, uploadCsv } from '../actions/contactActions';
import sampleCsv from '../assets/sample.csv';
import { fetchContactsFromList } from '../services/apiService';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#333',
    color: '#fff',
  },
  table: {
    minWidth: 650,
    backgroundColor: '#888',
  },
  tableCell: {
    color: '#fff',
    backgroundColor: '#666',
  },
  tableHeadCell: {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#000',
  },
  uploadButton: {
    marginBottom: theme.spacing(2),
    float: 'right',
    backgroundColor: '#00796b',
    color: '#fff',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(4),
    width: '50%',
    backgroundColor: '#555',
    color: '#fff',
  },
  note: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sampleCsv: {
    marginTop: theme.spacing(2),
    backgroundColor: '#666',
    padding: theme.spacing(2),
    borderRadius: 4,
    color: '#fff',
    fontFamily: 'monospace',
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  searchBox: {
    width: '30%',
    '& .MuiInputBase-root': {
      color: '#fff',
    },
    '& .MuiInputLabel-root': {
      color: '#bbb',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#bbb',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
  alert: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#555',
    color: '#fff',
  },
  copyIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    fontSize: '0.8rem',
    color: '#fff',
  },
}));

const ContactList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contactLists = useSelector((state) => state.contact.contactLists) || [];
  const uploadError = useSelector((state) => state.contact.uploadError);
  const [modalOpen, setModalOpen] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [listName, setListName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [csvModalOpen, setCsvModalOpen] = useState(false);
  const [selectedListData, setSelectedListData] = useState([]);

  useEffect(() => {
    const clientId = JSON.parse(localStorage.getItem('auth'))._id;
    dispatch(getContactLists(clientId));
  }, [dispatch]);

  const validateCsvFormat = (data) => {
    if (data.length === 0) return false;
    const expectedHeaders = ['number', 'first_name', 'last_name', 'company_name', 'email', 'tag', 'custom_field'];
    const headers = Object.keys(data.length > 0 && data[0]);

    return expectedHeaders.every(header => headers.includes(header));
  };

  const fetchContactsList = async (listSid) => {
    try {
      const response = await fetchContactsFromList(listSid);
      const data =  response.data;
      
      if (data.response) {
        const contacts = data.response.map(item => item.data);
        setSelectedListData(contacts); // Save all the contacts, not just a sample
      } else {
        setSelectedListData([]);
      }

      setCsvModalOpen(true);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setSelectedListData([]);
    }
  };

  const handleViewCsvModalOpen = (listSid) => {
    fetchContactsList(listSid);
  };

  const downloadCsv = () => {
    const csvData = Papa.unparse(selectedListData, {
      header: true,
      columns: [
        'number',
        'first_name',
        'last_name',
        'company_name',
        'email',
        'tag',
        'custom_field'
      ]
    });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'contacts.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCsvUpload = async () => {
    if (csvFile && listName) {
      const formData = new FormData();
      formData.append('file_name', csvFile);
      formData.append('list_name', listName);
      formData.append('type', 'static');

      Papa.parse(csvFile, {
        complete: async (results) => {
          if (results.data.length > 0 && validateCsvFormat(results.data)) {
            try {
              const response = await dispatch(uploadCsv(formData));
              if (response.status === 'success') {
                const uploadData = {
                  list_sid: response.data.summary.list_sid,
                  listName,
                  clientId: JSON.parse(localStorage.getItem('auth'))._id,
                  response: response.data,
                };

                await dispatch(uploadCsv(uploadData));
                setAlertMessage('CSV uploaded successfully!');
                setAlertSeverity('success');
              } else {
                throw new Error('CSV upload failed');
              }
            } catch (error) {
              setAlertMessage(error.message);
              setAlertSeverity('error');
            }

            setAlertOpen(true);
            setModalOpen(false);
          } else if (results.data.length === 0) {
            setAlertMessage('Empty CSV');
            setAlertSeverity('error');
            setAlertOpen(true);
          } else {
            setAlertMessage('CSV format is incorrect. Please ensure it matches the sample format.');
            setAlertSeverity('error');
            setAlertOpen(true);
          }
        },
        header: true,
      });
    }
  };

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleViewCsvModalClose = () => {
    setCsvModalOpen(false);
    setSelectedListData([]);
  };

  const filteredContactLists = Array.isArray(contactLists) && contactLists.length > 0
    ? contactLists.filter((list) => (
        list.list_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        list.listSid.toLowerCase().includes(searchTerm.toLowerCase())
      ))
    : [];

  return (
    <Box className={classes.root}>
      <Box className={classes.filterSection}>
        <TextField
          label="Search by Name or SID"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          className={classes.searchBox}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.uploadButton}
          onClick={() => setModalOpen(true)}
        >
          Upload CSV
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="contact list table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>List SID</TableCell>
              <TableCell className={classes.tableHeadCell}>List Name</TableCell>
              <TableCell className={classes.tableHeadCell}>Date Created</TableCell>
              <TableCell className={classes.tableHeadCell}>Contact Count</TableCell>
              <TableCell className={classes.tableHeadCell}>View CSV</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredContactLists
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((list) => (
                <TableRow key={list._id}>
                  <TableCell className={classes.tableCell}>
                    {list.listSid.slice(0, 5)}...
                    <IconButton className={classes.copyIcon} onClick={() => handleCopyToClipboard(list.listSid)}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.tableCell}>{list.list_name}</TableCell>
                  <TableCell className={classes.tableCell}>
                    {format(new Date(list.response.list.date_created), 'do MMMM yyyy, hh:mm a')}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {list.listSize !== null ? list.listSize : 'N/A'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleViewCsvModalOpen(list.listSid)}
                    >
                      View CSV
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredContactLists.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ color: '#fff' }}
      />
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        className={classes.modal}
      >
        <Box className={classes.paper}>
          <Typography variant="h6">Upload CSV</Typography>
          <Typography variant="body2" className={classes.note}>
            Please ensure your CSV file is in the following format (all fields are required):
            <Box component="pre" className={classes.sampleCsv}>
              number,first_name,last_name,company_name,email,tag,custom_field
            </Box>
          </Typography>
          <a href={sampleCsv} download style={{ color: '#00796b' }}>
              Download sample.csv
            </a>
          <TextField
            label="List Name"
            variant="outlined"
            fullWidth
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            style={{ marginBottom: 20, color: '#fff' }}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <TextField
            type="file"
            variant="outlined"
            fullWidth
            onChange={handleFileChange}
            style={{ color: '#fff' }}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          {uploadError && (
            <Typography variant="body2" className={classes.errorText}>
              {uploadError}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleCsvUpload}
            style={{ marginTop: 20, backgroundColor: '#00796b', color: '#fff' }}
            fullWidth
          >
            Upload
          </Button>
        </Box>
      </Modal>
      {alertOpen && (
        <Alert
          severity={alertSeverity}
          onClose={() => setAlertOpen(false)}
          className={classes.alert}
        >
          {alertMessage}
        </Alert>
      )}
      <Dialog
        open={csvModalOpen}
        onClose={handleViewCsvModalClose}
      >
        <DialogTitle>CSV Data Preview</DialogTitle>
        <DialogContent dividers>
          {selectedListData.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Number</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Tag</TableCell>
                  <TableCell>Custom Field</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedListData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.number}</TableCell>
                    <TableCell>{row.first_name}</TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell>{row.company_name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.tag}</TableCell>
                    <TableCell>
                      {typeof row.custom_field === 'object' && row.custom_field !== null
                        ? JSON.stringify(row.custom_field)
                        : row.custom_field}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography>No data available</Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={downloadCsv}
            style={{ marginTop: 20 }}
          >
            Download Full CSV
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ContactList;
