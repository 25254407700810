import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { fetchClientData } from '../../services/apiService';

// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css for react-confirm-alert
import { createCampaign, getCampaigns } from '../../actions/campaignActions';
import { getContactLists } from '../../actions/contactActions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(4),
    width: '40%',
    backgroundColor: '#555',
    color: '#fff',
    margin: 'auto',
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiInputLabel-root': {
      color: '#fff',
    },
    '& .MuiInputBase-root': {
      color: '#fff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#00796b',
    color: '#fff',
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dropdownContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  dropdown: {
    width: '48%', // Adjust width for two inputs in one row
  },
  retrySettingsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  retryItem: {
    flex: 1,
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}));

const CreateCampaignModal = ({ modalOpen, setModalOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contactLists = useSelector((state) => state.contact.contactLists) || [];
  const callerNumbers = JSON.parse(localStorage.getItem('auth'))?.callerNumbers || [];
  const [campaignName, setCampaignName] = useState('');
  const [selectedLists, setSelectedLists] = useState([]);
  const [callerNumber, setCallerNumber] = useState('');
  const [appId, setAppId] = useState('');
  const [retryEnabled, setRetryEnabled] = useState(false);
  const [retries, setRetries] = useState({
    number_of_retries: 0,
    interval_mins: 1,
    mechanism: 'Exponential',
    on_status: ['failed', 'busy', 'no-answer'],
  });

  useEffect(() => {
    const clientId = JSON.parse(localStorage.getItem('auth'))._id;
    dispatch(getContactLists(clientId));
  }, [dispatch]);

  const fetchClientBill = async () => {
    
  const clientId =  JSON.parse(localStorage.getItem('auth'))._id;
    const response = await fetchClientData(clientId);
    return response.data;
  };

  const handleCreateCampaign = async () => {
    if (campaignName && selectedLists.length > 0 && callerNumber && appId) {
      try {
        const clientId = JSON.parse(localStorage.getItem('auth'))._id;
  
        // Fetch client data before proceeding
        const clientData = await fetchClientBill();
  
        if (clientData.isActiveCamp === 1) {
          // Close modal first
          setModalOpen(false);
  
          // Show a SweetAlert alert if a campaign is already running
          Swal.fire({
            icon: 'warning',
            title: 'Campaign Running',
            text: 'One campaign is already running. Please wait until it is completed.',
            confirmButtonText: 'OK',
          });
        } else {
          const totalListSize = selectedLists.reduce((sum, listSid) => {
            const list = contactLists.find((list) => list.listSid === listSid);
            return sum + (list?.listSize || 0);
          }, 0);
  
          const balToken = totalListSize * 60 * 1003;
          const availableBalance = clientData.availableBalance;
          const blockedBalance = totalListSize * 60;
  
          // Close modal before showing confirmation dialog
          setModalOpen(false);
  
          // Show a confirmation dialog with SweetAlert
          Swal.fire({
            title: 'Confirm Campaign',
            text: `Available balance: ${availableBalance}, Balance to be blocked: ${blockedBalance}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then(async (result) => {
            if (result.isConfirmed) {
              // Create campaign after confirmation
              const campaignData = {
                clientId,
                camp_name: campaignName,
                listId: selectedLists,
                balToken,
                appId,
                callerNumber,
                retries: retryEnabled ? retries : {
                  number_of_retries: 0,
                  interval_mins: 1,
                  mechanism: 'Exponential',
                  on_status: ['failed', 'busy', 'no-answer'],
                },
              };
  
              await dispatch(createCampaign(campaignData));
              await dispatch(getCampaigns(clientId));
  
              // Optionally show a success alert after creating the campaign
              Swal.fire({
                icon: 'success',
                title: 'Campaign Created',
                text: 'Your campaign was successfully created.',
                confirmButtonText: 'OK',
              });
            }
          });
        }
      } catch (error) {
        console.error('Error creating campaign:', error);
      }
    } else {
      console.error('Please fill out all fields.');
    }
  };
  
  const handleListChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 5) {
      setSelectedLists(
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };

  const handleDeleteList = (listId) => {
    setSelectedLists((prevSelectedLists) => prevSelectedLists.filter((id) => id !== listId));
  };

  const handleRetryChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === 'on_status') {
      setRetries((prevRetries) => ({
        ...prevRetries,
        [name]: checked
          ? [...prevRetries[name], value]
          : prevRetries[name].filter((status) => status !== value),
      }));
    } else {
      setRetries((prevRetries) => ({
        ...prevRetries,
        [name]: value,
      }));
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      className={classes.modal}
    >
      <Box className={classes.paper}>
        <Typography variant="h6">Create Campaign</Typography>
        <TextField
          label="Campaign Name"
          variant="outlined"
          fullWidth
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          style={{ marginBottom: 20 }}
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          InputProps={{
            style: { color: '#fff' },
          }}
        />
        <Box className={classes.dropdownContainer}>
          <TextField
            label="App ID"
            variant="outlined"
            value={appId}
            onChange={(e) => setAppId(e.target.value)}
            className={classes.dropdown}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
          />
          <FormControl variant="outlined" className={classes.dropdown}>
            <InputLabel>Caller Number</InputLabel>
            <Select
              value={callerNumber}
              onChange={(e) => setCallerNumber(e.target.value)}
              input={<OutlinedInput label="Caller Number" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: '#555',
                    color: '#fff',
                  },
                },
              }}
            >
              {callerNumbers.map((number) => (
                <MenuItem
                  key={number}
                  value={number}
                  style={{ color: '#fff' }}
                >
                  {number}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Select Lists</InputLabel>
          <Select
            multiple
            value={selectedLists}
            onChange={handleListChange}
            input={<OutlinedInput label="Select Lists" />}
            renderValue={(selected) => (
              <Box className={classes.chipsContainer}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={contactLists.find((list) => list.listSid === value)?.list_name || value}
                    className={classes.chip}
                    onDelete={() => handleDeleteList(value)}
                    deleteIcon={<CloseIcon style={{ color: '#fff' }} />}
                  />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: '#555',
                  color: '#fff',
                },
              },
            }}
          >
            {contactLists.map((list) => (
              <MenuItem
                key={list.listSid}
                value={list.listSid}
                style={{ color: '#fff' }}
              >
                {list.list_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={retryEnabled}
              onChange={(e) => {
                setRetryEnabled(e.target.checked);
                if (!e.target.checked) {
                  setRetries((prevRetries) => ({
                    ...prevRetries,
                    number_of_retries: 0,
                  }));
                }
              }}
              style={{ color: '#fff' }}
            />
          }
          label="Enable Retry"
          style={{ marginBottom: 20, color: '#fff' }}
        />
        {retryEnabled && (
          <>
            <Box className={classes.retrySettingsRow}>
              <TextField
                label="Number of Retries"
                variant="outlined"
                type="number"
                value={retries.number_of_retries}
                onChange={(e) => handleRetryChange({ target: { name: 'number_of_retries', value: Math.max(0, Math.min(e.target.value, 3)) } })}
                className={classes.retryItem}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                InputProps={{
                  style: { color: '#fff' },
                }}
              />
              <TextField
                label="Interval (mins)"
                variant="outlined"
                type="number"
                value={retries.interval_mins}
                onChange={(e) => handleRetryChange({ target: { name: 'interval_mins', value: e.target.value } })}
                className={classes.retryItem}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                InputProps={{
                  style: { color: '#fff' },
                }}
              />
              <FormControl variant="outlined" className={classes.retryItem}>
                <InputLabel>Mechanism</InputLabel>
                <Select
                  value={retries.mechanism}
                  onChange={(e) => handleRetryChange({ target: { name: 'mechanism', value: e.target.value } })}
                  input={<OutlinedInput label="Mechanism" />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: '#555',
                        color: '#fff',
                      },
                    },
                  }}
                >
                  <MenuItem value="Exponential" style={{ color: '#fff' }}>
                    Exponential
                  </MenuItem>
                  <MenuItem value="Linear" style={{ color: '#fff' }}>
                    Linear
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className={classes.retryCheckboxes}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={retries.on_status.includes('failed')}
                    onChange={(e) => handleRetryChange({ target: { name: 'on_status', value: 'failed', checked: e.target.checked } })}
                    name="on_status"
                    value="failed"
                    style={{ color: '#fff' }}
                  />
                }
                label="Failed"
                style={{ color: '#fff' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={retries.on_status.includes('busy')}
                    onChange={(e) => handleRetryChange({ target: { name: 'on_status', value: 'busy', checked: e.target.checked } })}
                    name="on_status"
                    value="busy"
                    style={{ color: '#fff' }}
                  />
                }
                label="Busy"
                style={{ color: '#fff' }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={retries.on_status.includes('no-answer')}
                    onChange={(e) => handleRetryChange({ target: { name: 'on_status', value: 'no-answer', checked: e.target.checked } })}
                    name="on_status"
                    value="no-answer"
                    style={{ color: '#fff' }}
                  />
                }
                label="No Answer"
                style={{ color: '#fff' }}
              />
            </Box>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateCampaign}
          style={{ marginTop: 20, backgroundColor: '#00796b', color: '#fff' }}
          fullWidth
        >
          Create Campaign
        </Button>
      </Box>
    </Modal>
  );
};

export default CreateCampaignModal;
