import axios from 'axios';

const apiGlimpass = axios.create({
  baseURL: 'https://application.glimpass.com/interlogue',
});
const apiGlimpassExotel = axios.create({
  baseURL: 'https://application.glimpass.com/exotel',
});

export const login = (credentials) => apiGlimpass.post('/get-client', credentials);
export const register = (userData) => apiGlimpass.post('/register', userData);
export const createAssistant = (assistantData) => apiGlimpass.post('/create-assistant', assistantData);
export const getAssistants = (clientId) => apiGlimpass.post('/get-assistant-by-client', clientId);
export const getContactListsApi = (clientId) => apiGlimpassExotel.post('/get-list-by-clientId', { clientId });

export const uploadCsvApi = async (formData) => {
  try {
    const response = await apiGlimpassExotel.post(`/upload-csv`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message);
  }
};

export const uploadCsvFurtherApi = (data) => apiGlimpassExotel.post('/create-list', data); // Second API call

export const getCampaignsApi = (clientId) => {
  return apiGlimpassExotel.post(`/get-camp-by-clientId`,{clientId});
};

export const createCampaignApi = (campaignData) => {
  return apiGlimpassExotel.post('/campaign-call', campaignData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getReportApi = (clientId, campaignId,camp_name) => {
  return apiGlimpassExotel.post('/get-call-report-by-campaign',{clientId,campaignId, camp_name});
};

export const getCampaignDetailsApi = (campaignId) => {
  return apiGlimpassExotel.post('/get-campaign-details',{campaignId});
};

export const fetchClientData = (clientId) => {
  return apiGlimpass.post('/get-client-by-clientId',{clientId});
};

export const fetchContactsFromList = (listSid) => { 
  return apiGlimpassExotel.post('/get-contacts-from-list',{listSid});
};


export default apiGlimpass;
