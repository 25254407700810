import { GET_CAMPAIGNS, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_FAILURE, GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_FAILURE } from './types';
import { getCampaignsApi, getReportApi } from '../services/apiService';

export const getCampaigns = (clientId) => async (dispatch) => {
  dispatch({ type: GET_CAMPAIGNS });
  try {
    const response = await getCampaignsApi(clientId);
    dispatch({
      type: GET_CAMPAIGNS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CAMPAIGNS_FAILURE,
      payload: error.message,
    });
  }
};

export const getReport = (clientId, campaignId, camp_name) => async (dispatch) => {
  dispatch({ type: GET_REPORT });
  try {
    const response = await getReportApi(clientId, campaignId, camp_name);
    console.log('API Response:', response.data); // Log the response for debugging
    dispatch({
      type: GET_REPORT_SUCCESS,
      payload: response.data,
    });
    return response.data;  // Return the API response to be used in .then()
  } catch (error) {
    dispatch({
      type: GET_REPORT_FAILURE,
      payload: error.message,
    });
    throw error;  // Rethrow the error to be caught in .catch()
  }
};
