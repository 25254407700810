import { GET_CONTACT_LISTS_SUCCESS, UPLOAD_CSV, UPLOAD_CSV_ERROR, GET_CONTACT_LISTS_FAILURE } from '../actions/types';

const initialState = {
  contactLists: [],
  uploadResponse: null,
  uploadError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONTACT_LISTS_SUCCESS:
      console.log("Reducer: GET_CONTACT_LISTS_SUCCESS", action.payload);
      return {
        ...state,
        contactLists: action.payload,
      };
    case GET_CONTACT_LISTS_FAILURE:
      console.error("Reducer: GET_CONTACT_LISTS_FAILURE", action.payload);
      return {
        ...state,
        uploadError: action.payload,
      };
    case UPLOAD_CSV:
      console.log("Reducer: UPLOAD_CSV", action.payload);
      return {
        ...state,
        uploadResponse: action.payload,
        uploadError: null,
      };
    case UPLOAD_CSV_ERROR:
      console.error("Reducer: UPLOAD_CSV_ERROR", action.payload);
      return {
        ...state,
        uploadResponse: null,
        uploadError: action.payload,
      };
    default:
      return state;
  }
}
