export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_CONTACT_LISTS = 'GET_CONTACT_LISTS';
export const UPLOAD_CSV = 'UPLOAD_CSV';
export const UPLOAD_CSV_ERROR = 'UPLOAD_CSV_ERROR';
export const GET_CONTACT_LISTS_SUCCESS = 'GET_CONTACT_LISTS_SUCCESS';
export const GET_CONTACT_LISTS_FAILURE = 'GET_CONTACT_LISTS_FAILURE';

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAILURE = 'GET_CAMPAIGNS_FAILURE';

export const GET_CAMPAIGN_DETAILS = 'GET_CAMPAIGN_DETAILS';
export const GET_CAMPAIGN_DETAILS_SUCCESS = 'GET_CAMPAIGN_DETAILS_SUCCESS';
export const GET_CAMPAIGN_DETAILS_FAILURE = 'GET_CAMPAIGN_DETAILS_FAILURE';

export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE';

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';
