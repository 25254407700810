import React from 'react';
import { Typography, Container } from '@material-ui/core';

const Account = () => {
  return (
    <Container>
      <Typography variant="h4">This is the Account Page</Typography>
      <Typography variant="body1">Account settings and details go here.</Typography>
    </Container>
  );
};

export default Account;
