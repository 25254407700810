import {
    FETCH_BILLING_HISTORY_SUCCESS,
    FETCH_CLIENT_SUCCESS,
    FETCH_BILLING_ERROR,
    FETCH_CLIENT_ERROR
} from '../actions/billingActions';

const initialState = {
    billingHistory: [],
    clientData: {},
    error: null,
};

const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BILLING_HISTORY_SUCCESS:
            return {
                ...state,
                billingHistory: action.payload,
            };
        case FETCH_CLIENT_SUCCESS:
            return {
                ...state,
                clientData: action.payload,
            };
        case FETCH_BILLING_ERROR:
        case FETCH_CLIENT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default billingReducer;
