import {
  GET_CAMPAIGN_DETAILS,
  GET_CAMPAIGN_DETAILS_SUCCESS,
  GET_CAMPAIGN_DETAILS_FAILURE,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAILURE,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILURE
} from '../actions/types';

const initialState = {
  campaigns: [],
  campaignDetails: null,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CAMPAIGNS:
    case CREATE_CAMPAIGN:
    case GET_CAMPAIGN_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        loading: false,
      };
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, action.payload],
        loading: false,
      };
    case GET_CAMPAIGN_DETAILS_SUCCESS:
      return {
        ...state,
        campaignDetails: action.payload,
        loading: false,
      };
    case GET_CAMPAIGNS_FAILURE:
    case CREATE_CAMPAIGN_FAILURE:
    case GET_CAMPAIGN_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
