import { GET_CONTACT_LISTS, UPLOAD_CSV, UPLOAD_CSV_ERROR, GET_CONTACT_LISTS_SUCCESS, GET_CONTACT_LISTS_FAILURE } from './types';
import { getContactListsApi, uploadCsvApi, uploadCsvFurtherApi } from '../services/apiService';

export const getContactLists = (clientId) => async (dispatch) => {
  try {
    const response = await getContactListsApi(clientId);
    const data = response.data;
    console.log(data, "Fetched contact lists");
    if (Array.isArray(data)) {
      dispatch({
        type: GET_CONTACT_LISTS_SUCCESS,
        payload: data,
      });
    } else {
      throw new Error('Data is not an array');
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: GET_CONTACT_LISTS_FAILURE,
      payload: error.message,
    });
  }
};

export const uploadCsv = (formData) => async (dispatch) => {
  try {
    const exotelRes = await uploadCsvApi(formData);
    console.log(exotelRes.response.data, "Exotel response");
    const { status, summary } = exotelRes.response.data;
    const httpcode = exotelRes.http_code;
    console.log(httpcode, "HTTP code");

    if (httpcode == '200') {
      const { list_sid } = summary;

      const uploadData = {
        listSid: list_sid,
        listName: formData.get('list_name'),
        clientId: JSON.parse(localStorage.getItem('auth'))._id,
        listSize: exotelRes.listSize,
        response: exotelRes.response.data,
      };

      const uploadRes = await uploadCsvFurtherApi(uploadData);

      dispatch({
        type: UPLOAD_CSV,
        payload: uploadRes.data,
      });
    } else {
      throw new Error('Exotel upload failed');
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: UPLOAD_CSV_ERROR,
      payload: err.message,
    });
  }
};
